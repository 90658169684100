<script>

export default {}

</script>

<template>
	<div class="ViewLayout">

		<div class="header">
			
			<ul class="tabs">
				<li><router-link to="/">Bar</router-link></li>
				<li><router-link to="/admin">Evenements</router-link></li>
			</ul>
			
			<div class="action" v-if="$slots.action">
				<slot name="action" clas="blob" />
			</div>
			
			<div class="tools" v-if="$slots.tools">
				<slot name="tools" />
			</div>
			
			<div class="user" v-if="$slots.user">
				<slot name="user" />
			</div>
			
		</div>

		<div class="content">
			<slot />
		</div>

	</div>
</template>

<style scoped>

/*** LAYOUT ***/

.ViewLayout {
	min-height: 100vh;
	padding-bottom: 5rem;
}

/*** Header ***/

/* Out */
.header {
	position: sticky;
	top: 0;
	z-index: 10;
	margin-bottom: 1rem;
}

/* In */
.header {
	display: flex;
	align-items: center;
}
.header > .tabs {
	flex: 1;
	margin: 0;
	padding: 0;
}
.header > .tabs > li {
	display: inline-block;
}
.header > .tabs a {
	display: block;
	padding: .5em .6em;
}

/*** Action ***/

/* S */
@media screen and (max-width: 540px) {
	.action {
		position: fixed;
		bottom: 1em;
		right: 1em;
		z-index: 100;
	}
}

/* M */
@media screen and (min-width: 541px) {
	.action {
		margin-right: 1rem;
	}
}

/*** Tools ***/

/* S */
@media screen and (max-width: 540px) {
	.tools {
		position: fixed;
		z-index: 10;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1rem;
	}
}

/* M */
@media screen and (min-width: 541px) {
	.tools {
		margin-right: 1rem;
	}
}

/*** User ***/

.user {
	margin-right: 1rem;
}


/*** STYLE ***/

.ViewLayout {
	background-color: #222;
	color: #FFF;
}

.header, .tools, .modal {
	background-color: #000;
}

.overlay {
	background-color: rgba(0,0,0,.6);
}

.header > .tabs {
	font-weight: normal;
	font-size: 130%;
}
.header > .tabs a {
	color: #AAA;
	border-bottom: solid 3px transparent;
}
.header > .tabs a:hover, .header > .tabs a:focus {
	color: #DDD;
	border-color: #DDD;
}
.header > .tabs a.router-link-exact-active {
	border-color: var(--app-accent-color);
	color: #FFF;
}

</style>
