<script>
import { state } from './state'

import ViewModal from '@/components/ViewModal.vue'
import ViewLayout from '@/components/ViewLayout.vue'
import WeeklyCalendar from '@/components/WeeklyCalendar.vue'

export default {

	components: { ViewModal, ViewLayout, WeeklyCalendar },

	filters: {
		format (instant, fmt) {
			return instant.toFormat(fmt).replace('.', '');
		}
	},
	
	data: () => ({
		state,
		loading: false,
		saved: false
	}),

	async mounted() {
		this.loading = true;
		await state.load();
		this.loading = false;
	}
}

</script>

<template>
	<ViewLayout class="AdminView">
		
		<div slot="tools">
			<button
				class="button secondary"
				:disabled="!state.changedCount"
				@click="state.revertEvents()">
				Annuler
			</button>
			<button
				class="button primary"
				:disabled="!state.changedCount"
				@click="state.saveEvents(), saved = true">
				Valider {{ state.changedCount }} changements
			</button>
		</div>
		
		<WeeklyCalendar :weeks="state.weeks" v-slot="{ day }">
			<div class="event" :class="{ pending: state.hasEvent(day) }">
			
				<div class="date">
					<span>{{ day.instant | format('ccc') }}</span> {{ day.instant | format('d LLL') }}
				</div>
				
				<input
					class="input"
					:value="state.getEvent(day)"
					@input="state.setEvent(day, $event.target.value)" />

			</div>
		</WeeklyCalendar>
		
		<ViewModal v-if="saved" @click="saved = false" @click-out="saved = false">
			<FaIcon icon="check" /> Modifié
		</ViewModal>

	</ViewLayout>
</template>

<style scoped>

.event {
	padding: .4em;
	background-color: #444;
	border-left: solid 4px transparent;
}
.event.pending {
	border-color: var(--app-accent-color);	
}

.date {
	color: #999;
}
.date > span {
	font-weight: 700;
}

.input {
	margin: .3em 0;
	width: 100%;
}

button {
	margin-left: .5em;
}

</style>
