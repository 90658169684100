import Vue from 'vue'
import { DateTime } from 'luxon'
import { AssigneeType } from './api';

export class HasInstant {
	constructor (public readonly instant: DateTime) {}
	valueOf() { return this.instant.valueOf() }
}

export class Assignee {

	public selected: boolean = false;

	constructor(public readonly name: string) {}
}

export class Occurrence extends HasInstant {

	public assignees: [Assignee, AssigneeType][] = [];
	public pending: { originalType: AssigneeType | false } | undefined = undefined;

	constructor (instant: DateTime) {
		super(instant);
	}
	
	hasAssignee(assignee: Assignee) {
		return this.assignees.find(kv => kv[0] === assignee);
	}
	
	getType(assignee: Assignee): AssigneeType | undefined {
		const kv = this.assignees.find(kv => kv[0] === assignee);
		return kv ? kv[1] : undefined;
	}
	
	setType(assignee: Assignee, type: AssigneeType | false) {
		if (type !== false) {
			const kv = this.assignees.find(kv => kv[0] === assignee);
			if (kv)
				Vue.set(kv, 1, type);
			else
				this.assignees.push([assignee, type]);
		} else {
			const i = this.assignees.findIndex(kv => kv[0] === assignee);
			if (i > -1) this.assignees.splice(i, 1);
		}
	}
	
	getTypeCount(type: AssigneeType) {
		return this.assignees.reduce(
			(count, kv) =>  count + (kv[1] === type ? 1 : 0),
			0
		);
	}
}

export class Day extends HasInstant {

	public readonly occurrences: Occurrence[] = [];

	constructor (instant: DateTime, public event?: string) {
		super(instant.startOf('day'));
	}

	hasSame(instant: DateTime) {
		return this.instant.hasSame(instant, 'day');
	}

}

export class Week extends HasInstant {

	public readonly days: Day[] = [];

	constructor (instant: DateTime) {
		super(instant.startOf('week'));
	}

	hasSame(instant: DateTime) {
		return this.instant.hasSame(instant, 'week');
	}
}

export class Month extends HasInstant {

	public readonly weeks: Week[] = [];

	constructor (instant: DateTime) {
		super(instant.startOf('month'));
	}

	hasSame(instant: DateTime) {
		return this.instant.hasSame(instant, 'year') && this.instant.hasSame(instant, 'month');
	}
}

