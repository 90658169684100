<script>

export default {
	components: {  }
}
</script>

<template>
	<router-view/>
</template>

<style>

/* Normalize */

*, *::before, *::after {
	box-sizing: border-box;
}

body {
	overflow-y: auto;
}
html, body {
	margin: 0;
	padding: 0;
	height: 100%;
}

a {
	color: inherit;
	text-decoration: inherit;
	outline: none;
}

button {
	border: none;
	background: none;
	outline: none;
	padding: 0;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	cursor: pointer;
}

input {
	border: none;
	background: none;
	outline: none;
}

/* Global */

body {
	--app-accent-color: rgb(130, 103, 189);
}

body {
	margin: 0 auto;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 16px;
	background: #222;
}

/* Form */

input.input {
	border: solid 2px transparent;
	background: #DDD;
	border-radius: 100px;
	padding: .5em .8em;
}
input.input:focus {
	background: #FFF;
	border-color: var(--app-accent-color);
}

button.button.link {
	color: var(--app-accent-color);
}
button.button.link:hover, button.button.link:focus {
	text-decoration: underline;
}

button.button.primary, button.button.secondary {
	border-radius: 100px;
	padding: .5em 1em;
}
button.button.primary {
	background-color: var(--app-accent-color);
	color: #FFF;
}
button.button.primary:not(:disabled):hover, button.button.primary:not(:disabled):focus, button.button.primary:not(:disabled):active {
	color: var(--app-accent-color);
	background-color: #FFF;
}
button.button.secondary {
	color: var(--app-accent-color);
	border: solid 2px;
}
button.button.secondary:not(:disabled):hover, button.button.secondary:not(:disabled):focus, button.button.secondary:not(:disabled):active {
	color: #FFF;
}

button.button:disabled {
	opacity: .4;
	cursor: auto;
}

</style>
