import Vue from 'vue'
import VueRouter from 'vue-router'
import PermView from './views/PermView/PermView.vue'
import AdminView from './views/AdminView/AdminView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: PermView
	},
	{
		path: '/admin',
		component: AdminView
	}
]

const router = new VueRouter({
  routes
})

export default router
