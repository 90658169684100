import { DateTime } from 'luxon'
import { Api, AssigneeType, GetData, SetData } from './api'
import { Week, Day, Occurrence, Assignee } from './model';

const EMPTY_DATE = DateTime.invalid('EMPTY');
const EMPTY_DAY = new Day(EMPTY_DATE);
const EMPTY_OCC = new Occurrence(EMPTY_DATE);

/**
 * Computes a week/day/occurrence tree from given slots and pre-existing occurrences.
 *
 * @param weeks Week/day/occurrence tree to append to
 * @param start Start of the computation period
 * @param end End of the computation period
 * @param slots Slots to compute occurrences from
 * @param occurrences Pre-existing occurrences to merge into the resulting tree
 * @returns `weeks` argument
 */
export function createOccurrencesByWeek({slots, occurrences, events}: GetData, weekCount: number): { weeks: Week[], assignees: Assignee[] } {

	// Guards
	slots = slots ?? [];
	occurrences = occurrences ?? {};
	events = events ?? {};
	
	// All existing weekdays, sorted
	const weekdays = sortAndDedup(slots.map(r => r.weekday));

	// Period
	let start = DateTime.local().startOf('day');
	while (!weekdays.includes(start.weekday as any)) start = start.plus({ days: 1});
	const end = start.plus({weeks: weekCount - 1 }).endOf('week');
	
	// Output
	const weeks = [] as Week[];
	const assignees = new Map<string, Assignee>();

	// Loop context
	let w = 0, s = 0;
	let week: Week | undefined;
	let day: Day | undefined;

	// Create occurrences
	for (;;) {

		// Get current slot & instant
		const slot = slots[s];
		const instant = start.set({ weekday: slot.weekday, hour: slot.hour, minute: slot.minute }).plus({ weeks: w });

		// Next slot & instant indexes
		if ((s = (s + 1) % slots.length) == 0) w++;

		// Period boundaries
		if (instant < start) continue;
		if (instant > end) break;

		// Current week
		if (!week || !week.hasSame(instant)) {
			week = push(weeks, new Week(instant));
			day = undefined;
		}

		// Current day
		if (!day || !day.hasSame(instant)) {
			day = push(week.days, new Day(instant, events[Api.d2str(instant)]));
		}

		// Current occurrence
		const dstOcc = new Occurrence(instant);
		day.occurrences.push(dstOcc);

		// Assignees
		const srcOcc = occurrences[Api.dt2str(instant)];
		const occAssignees = srcOcc?.assignees ?? {};
		for (const name of Object.keys(occAssignees)) {
			let a = assignees.get(name);
			if (!a) {
				a = new Assignee(name);
				assignees.set(name, a);
			}
			const type = occAssignees[name];
			dstOcc.assignees.push([a, type]);
		}
	}

	// Ensure all weeks have same number of days
	for (const week of weeks) {
		const days = week.days;
		// Enough days
		if (days.length >= weekdays.length) continue;
		// Add missing days
		for (let i = 0; i < weekdays.length; i++) {
			const day = days[i];
			if (!day || day.instant.weekday != weekdays[i]) {
				days.splice(i, 0, EMPTY_DAY);
			}
		}
	}

	// All existing times in the tree, sorted
	const times = sortAndDedup(slots.map(r => hm2time(r.hour, r.minute)));

	// Ensure all days have same number of occurrences
	for (const week of weeks)
	for (const day of week.days) {
		const occs = day.occurrences;
		// Enough occurrences
		if (occs.length >= times.length) continue;
		// Add missing occurrences
		for (let i = 0; i < times.length; i++) {
			const occ = occs[i];
			if (!occ || dt2time(occ.instant) != times[i]) {
				occs.splice(i, 0, EMPTY_OCC);
			}
		}
	}

	return { weeks, assignees: [ ...assignees.values() ] };
}

export function createAssigneeData(assignee: Assignee, occs: Occurrence[]): SetData {
	const map = {} as { [instant: string]: AssigneeType | false };
	for (const occ of occs)
		if (occ.pending)
			map[Api.dt2str(occ.instant)] = occ.getType(assignee) || false;
	return { assignees: { [assignee.name]: map } };
}

/******** UTILS ********/

function push<T>(arr: T[], el: T) {
	arr.push(el);
	return el;
}

function sortAndDedup<T>(arr: T[]) {
	arr.sort();
	for (let i = 1; i < arr.length; i++)
		if (+arr[i] === +arr[i-1])
			arr.splice(i--, 1);
	return arr;
}

function hm2time(h: number, m: number) {
	return h * 100 + m;
}

function dt2time(dt: DateTime) {
	return dt.hour * 100 + dt.minute;
}
