<script>

export default {
	props: [ 'nearToKeyboard' ]
}

</script>

<template>
	<div class="ViewModal" :class="{ bottom: nearToKeyboard }" @click="$emit('click-out')">
		<div class="content" @click.stop="$emit('click')">
			<slot />
		</div>
	</div>
</template>

<style scoped>

/*** LAYOUT ***/

.ViewModal {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.ViewModal {
	display: flex;
	flex-flow: column nowrap;
}

/* S */
@media screen and (max-width: 540px) {
	.ViewModal {
		align-items: stretch;
		justify-content: center;
	}
	.ViewModal.bottom {
		justify-content: flex-end;
	}
}

/* M */
@media screen and (min-width: 541px) {
	.ViewModal {
		align-items: center;
		justify-content: center;
	}
	.content {
		border-radius: 5px;
	}
}

.content {
	padding: 1.5rem 2rem;
}


/*** STYLE ***/

.ViewModal {
	background-color: rgba(0,0,0,.7);
}

.content {
	background-color: #000;
	color: #FFF;
}

</style>
