import Vue from 'vue'
import { Api, AssigneeType, GetData } from '@/model/api'
import { Week, Day, Occurrence, Assignee } from '@/model/model'
import { createOccurrencesByWeek, createAssigneeData } from '@/model/glue'

const STORAGE_ASSIGNEE_NAME = 'agendaAssigneeName';

function simpleString(name) {
	return name && name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const state = Vue.observable(new class State {

	public weeks: Week[] = [];
	public assignees: Assignee[] = [];
	public remember: boolean = false;

	public viewing = true;
	public editing = false;
	public selecting = false;

	private _data: GetData | undefined;

	/*** Load ***/

	public async load(): Promise<Week[]> {
		// Load data
		this._data = await Api.get();
		// Compute model
		const mdl = createOccurrencesByWeek(this._data, 4);
		this.weeks = mdl.weeks;
		this.assignees = mdl.assignees;
		// Done
		return this.weeks;
	}

	/*** State ***/

	public goView() {
		this.revertToggledOccurrences();
		this.resetCurrentAssignee();
		this.viewing = true;
		this.editing = false;
		this.selecting = false;
	}

	public goEdit() {
		this.viewing = false;
		this.editing = true;
		this.selecting = false;
	}

	public goSelectWithCurrent() {
		// Already selected
		if (this.currentAssignee) {
			this.setCurrentAssignee();
			this.goSelect();
			return;
		}
		// Remembered
		const name = localStorage?.getItem(STORAGE_ASSIGNEE_NAME);
		if (name) {
			this.setCurrentAssignee(name);
			this.remember = true;
			this.goSelect();
			return;
		}
		// No assignee
		this.goEdit();
	}

	public goSelectWith(name: string, remember: boolean) {
		// State
		this.setCurrentAssignee(name);
		this.remember = remember;
		// Store
		if (remember && this.currentAssignee)
			localStorage.setItem(STORAGE_ASSIGNEE_NAME, this.currentAssignee.name);
		else
			localStorage.removeItem(STORAGE_ASSIGNEE_NAME);
		// Go
		this.goSelect();
	}

	private goSelect() {
		this.viewing = false;
		this.editing = false;
		this.selecting = true;
	}

	public goSave() {
		this.saveToggledOccurrences();
		this.resetCurrentAssignee();
		this.viewing = true;
		this.editing = false;
		this.selecting = false;
	}

	/*** Selection ***/

	public currentAssignee: Assignee | undefined;

	public toggledOccurrences: Occurrence[] = [];

	private setCurrentAssignee(name?: string): void {
		// Set assignee
		if (name) {
			const sname = simpleString(name);
			let assignee = this.assignees.find(a => simpleString(a.name) == sname);
			if (!assignee) {
				assignee = new Assignee(name);
				this.assignees.push(assignee);
			}
			this.currentAssignee = assignee;
		}
		// Update occurrences
		for (const week of this.weeks)
		for (const day of week.days)
		for (const occ of day.occurrences) {
			occ.pending = undefined;
		}
		this.toggledOccurrences = [];
		// Update assignees
		for (const ass of this.assignees) {
			ass.selected = ass === this.currentAssignee;
		}
	}

	private resetCurrentAssignee(): void {
		this.currentAssignee = undefined;
		// Update assignees
		for (const ass of this.assignees) {
			ass.selected = false;
		}
	}

	public selectOccurrence(occurrence: Occurrence, newType: AssigneeType | false) {
		// Check
		if (!this.currentAssignee)
			throw new Error('Not in selection mode');
		// Original type
		const originalType =
			occurrence.pending?.originalType
			?? occurrence.getType(this.currentAssignee)
			?? false;
		// New type
		occurrence.setType(this.currentAssignee, newType);
		// Pending data
		if (newType === originalType) {
			occurrence.pending = undefined;
		} else {
			occurrence.pending = { originalType };
		}
		// Pending occurence
		toggleElement(this.toggledOccurrences, occurrence, !!occurrence.pending);
	}

	public async saveToggledOccurrences() {
		// Check
		if (!this.currentAssignee)
			throw new Error('Not in selection mode');
		// Send request
		await Api.set(
			createAssigneeData(this.currentAssignee, this.toggledOccurrences)
		);
		// Reset
		for (const occ of this.toggledOccurrences) {
			occ.pending = undefined;
		}
		this.toggledOccurrences = [];
	}

	public revertToggledOccurrences() {
		// Check
		if (!this.currentAssignee)
			return;
		for (const occ of this.toggledOccurrences) {
			if (occ.pending)
				occ.setType(this.currentAssignee, occ.pending.originalType);
			occ.pending = undefined;
		}
		this.toggledOccurrences = [];
	}
});

/*** Utils ***/

function toggleElement<T>(arr: T[], el: T, state: boolean) {
	const i = arr.indexOf(el);
	if (state && i < 0)
		arr.push(el);
	if (!state && i >= 0)
		arr.splice(i, 1);
}

function toggleMapElement<K, V>(map: Map<K, V>, k: K, v: V, deleteIf: V) {
	if (deleteIf === v)
		map.delete(k);
	else
		map.set(k, v);
}