import Vue from 'vue'
import { Api } from '@/model/api'
import { Week, Day } from '@/model/model'
import { createOccurrencesByWeek } from '@/model/glue'

export const state = Vue.observable(new class State {
	
	public weeks: Week[] = [];
	public changedCount = 0;
	
	private _changedEvents = new Map<Day, string>();
	private _changedEventsVersion = 1;
	
	public async load() {
		const data = await Api.get();
		const mdl = createOccurrencesByWeek(data, 4);
		this.weeks = mdl.weeks;
	}
	
	public hasEvent(day: Day) {
		this._changedEventsVersion;
		return this._changedEvents.has(day);
	}
	
	public getEvent(day: Day) {
		this._changedEventsVersion;
		const event = this._changedEvents.get(day);
		return event === undefined ? day.event : event;
	}
	
	public setEvent(day: Day, event: string) {
		
		if ((!day.event && !event) || day.event == event) {
			this._changedEvents.delete(day);
		} else {
			this._changedEvents.set(day, event);
		}
		this._changedEventsVersion++;
		
		this.changedCount = this._changedEvents.size;
	}
	
	public async saveEvents() {
		
		const events = {};
		for (const [day, event] of this._changedEvents) {
			events[Api.d2str(day.instant)] = event || null;
		}
		
		await Api.set({ events });
		
		for (const [day, event] of this._changedEvents) {
			day.event = event;
		}
		
		this.revertEvents();
	}
	
	public revertEvents() {
		this._changedEvents.clear();
		this._changedEventsVersion++;
		this.changedCount = 0;
	}
	
});