<script>

export default {

	props: ['selectionCount']

}
</script>

<template>
	<div class="ActionButtons">

			<button class="button secondary" @click="$emit('cancel')">
				Annuler
			</button>

			<button class="button primary" v-if="selectionCount" @click="$emit('submit')">
				<template v-if="selectionCount > 1">
					Je valide mes {{ selectionCount }} choix
				</template>
				<template v-else>
					Je valide mon choix
				</template>
			</button>

			<div class="no-selection" v-else>
				<span>Je coche mes choix</span> <FaIcon icon="level-up-alt"/>
			</div>

	</div>
</template>

<style scoped>

.ActionButtons {
	display: flex;
	align-items: center;
}

button, .no-selection {
	margin: .2em .5rem;
}

.no-selection {
	text-align: center;
}

@media screen and (max-width: 540px) {
	.ActionButtons {
		flex-flow: row wrap;
	}
	button, .no-selection {
		flex: 1 0 auto;
	}
}
@media screen and (min-width: 541px) {
	.ActionButtons  {
		flex-flow: row-reverse nowrap;
	}
	.no-selection {
		display: flex;
		flex-flow: row-reverse wrap;
	}
	.no-selection > svg {
		transform: translate(-50%, 25%) rotate(180deg) ;
	}
}

</style>
