<script>
import { state } from './state'

const TYPES = [ false, 'BEN', 'REF' ];

function comp(a0, a1) {
	return a0 > a1 ? 1 : a0 < a1 ? -1 : 0
}

function sortAssigneeTuples(kv0, kv1) {
	let r = comp(kv1[1], kv0[1]);
	if (r == 0) r = comp(kv0[0].name, kv1[0].name)
	return r;
}

export default {

	props: ['day'],

	data: () => ({ state }),

	filters: {
		format (instant, fmt) {
			return instant.toFormat(fmt).replace('.', '');
		}
	},

	computed: {

		assignees() {
			return this.day.occurrences
				.filter(o => o.instant.isValid)
				.flatMap(o => o.assignees)
				.sort(sortAssigneeTuples)
				.map(kv => kv[0])
				.filter((a, i, arr) => arr.indexOf(a) === i);
		},

		occurrences() {
			return this.day.occurrences;
		},

		firstOccurrenceIndex() {
			for (let i = 0; i < this.occurrences.length; i++)
				if (this.occurrences[i].instant.isValid)
					return i;
		},

		lastOccurrenceIndex() {
			for (let i = this.occurrences.length - 1; i >= 0; i--)
				if (this.occurrences[i].instant.isValid)
					return i;
		},

		isAssigneePresent() {
			return this.assignees.find(a => a.selected);
		}
	},

	methods: {

		status(occ) {
			
			if (!occ.instant.isValid)
				return undefined;
			
			const refs = occ.getTypeCount('REF'),
			      bens = occ.getTypeCount('BEN');
			
			if (refs == 0) return 'bad'
			if (bens == 0) return 'bad';
			if (bens == 1) return 'soso';
			
			return 'good'
		},

		position(o) {
			return o == 0 ? 'first' : o == this.occurrences.length - 1 ? 'last' : null;
		},
		
		text(occ, ass) {
			let type = occ.getType(ass);
			return type == 'BEN' ? 'Bénévole' : type == 'REF' ? 'Référent' : undefined;
		},

		selectOcc(occurrence) {
			let type = occurrence.getType(this.state.currentAssignee) || false;
			type = TYPES[(TYPES.indexOf(type) + 1)% TYPES.length];
			this.$emit('occurrenceSelected', { occurrence, type });
		}
	}
}

</script>

<template>
	<div class="PermViewDay"
	     :class="state.selecting && 'selecting'">

		<div class="header">
			<div class="date">
				<span>{{ day.instant | format('ccc') }}</span> {{ day.instant | format('d LLL') }}
			</div>
			<div class="event" v-if="day.event">
				{{ day.event }}
			</div>
		</div>
		
		<div class="content"
		     :style="{ gridTemplate: `auto repeat(${assignees.length || 1}, auto) / minmax(6em, 2fr) repeat(${occurrences.length || 1}, minmax(2em, 1fr))` }">

			<template v-for="(occ, o) in occurrences">

				<div class="time"
				     v-if="occ.instant.isValid"
				     :key="o + 1000"
				     :style="{ gridColumn: o + 2 }">
					{{ occ.instant.hour }}<span>h</span>
				</div>

				<div class="occ bg"
				     :key="o + 1100"
				     :class="[ status(occ), position(o), { pending: occ.pending } ]"
				     :style="{ gridColumn: o + 2 }">
				</div>

				<button class="occ fg"
				        v-if="state.selecting && occ.instant.isValid"
				       :key="o + 1200"
				       :class="[ position(o), { pending: occ.pending } ]"
				       :style="{ gridColumn: o + 2 }"
				       @click="selectOcc(occ)">
					<template v-if="!isAssigneePresent">
						<FaIcon icon="circle" class="plus bg" :class="status(occ)"/>
						<FaIcon icon="plus-circle" class="plus"/>
					</template>
				</button>

			</template>

			<template v-for="(ass, a) in assignees">

				<div class="name"
				     :key="a + 300"
				     :class="ass.selected && 'selected'"
				     :style="{ gridRow: a + 2 }">
					<span>{{ ass.name }}</span>
				</div>

				<div class="ass"
				     :key="a + 400"
				     :class="ass.selected && 'selected'"
				     :style="{ gridRow: a + 2 }">
				</div>

				<div class="check"
				     v-for="(occ, o) in occurrences"
				     v-if="occ.hasAssignee(ass)"
				     :key="a + 500 + o * 10"
				     :class="[ status(occ), { selected: ass.selected, ref: occ.getType(ass) == 'REF' } ]"
				     :style="{ gridColumn: o + 2, gridRow: a + 2 }">
					<span>{{ text(occ, ass) }}</span>
				</div>

			</template>
<!--
			<div class="all"
			     v-if="state.selecting && !isAssigneePresent"
					 :style="{ gridColumn: `${firstOccurrenceIndex + 2} / ${lastOccurrenceIndex + 3}` }">
				<FaIcon icon="plus-circle"/>
			</div>
-->
		</div>
	</div>
</template>

<style scoped>

.PermViewDay {
	position: relative;
	padding-bottom: .8em;
}

.header {
	margin-bottom: .8em;
	padding: .4em .8em .6em .8em;
	background: #393939;
}
.date, .event {
	display: inline-block;
}
.event {
	color: #EEE;
}
.date {
	margin-right: .8em;
}
.date > span {
	font-weight: 700;
}



.content {
	display: grid;
}
.time {
	grid-row-start: 1;
}
.name {
	grid-column-start: 1;
}
.occ {
	grid-row: 1 / -1;
}
.ass {
	grid-column: 1 / -1;
}
.occ.bg {
	z-index: 1;
}
.occ.fg {
	z-index: 3;
}
.ass {
	z-index: 2;
}
.time, .name, .check {
	z-index: 2;
}
.name, .check {
	align-self: center;
}
.time, .check {
	text-align: center;
}
.name {
	text-align: right;
}

.content {
	padding-bottom: 5px;
}
.time, .name, .occ, .check {
	margin-right: 16%;
}
.occ {
	margin-bottom: -3px;
	border-radius: 3px;
}

/***/

.time {
	padding: .3em .4em;
	line-height: 1rem;
	font-size: 90%;
	font-weight: 600;
}
.time > span {
	font-size: 80%;
	font-weight: 400;
}

.check {
	padding: .3em .8em;
}
.name > span, .check > span {
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.name, .check {
	line-height: 1rem;
	font-size: 70%;
}
.check {
	font-weight: 700;
	text-transform: uppercase;
}
.check.ref {
	background: rgba(255,255,255,.8);
}

.ass {
	border-top: solid 1px rgba(255,255,255,.1);
}
.ass.selected {
	border: none;
	background-color: rgba(255,255,255,.1);
}

.occ.bg {
	border: solid 1px transparent;
}

.occ.fg {
	position: relative;
	border: solid 2px;
	box-shadow: 0 0 10px #000;
	cursor: pointer;
}
.occ.fg > .plus {
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translate(-50%, -40%);
}
.occ.fg > .plus.bg {
	font-size: 90%;
}

.occ.bg.pending, .occ.fg.pending {
	border-color: var(--app-accent-color);
}

.selecting .name, .selecting .check {
	opacity: .3;
}
.selecting .name.selected, .selecting .check.selected {
	opacity: 1;
}

/***/

.PermViewDay {
	background: #444;
}

.date {
	color: #999;
}
.time {
	color: #555;
}
.name {
	color: #EEE;
}
.check, .occ.fg {
	color: #EEE;
}

.PermViewDay {
	--bad: #F04747;
	--soso: #E8BE14;
	--good: #75AD57;
}

.occ.bg {
	background: #4A4A4A;
}
.bad.occ.bg {
	background: var(--bad);
}
.soso.occ.bg {
	background: var(--soso);
}
.good.occ.bg {
	background: var(--good);
}

.bad.plus.bg {
	color: var(--bad);
}
.soso.plus.bg {
	color: var(--soso);
}
.good.plus.bg {
	color: var(--good);
}

.bad.check.ref {
	color: var(--bad);
}
.soso.check.ref {
	color: var(--soso);
}
.good.check.ref {
	color: var(--good);
}

</style>
