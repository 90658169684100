<script>
import autofocus from '@/components/autofocus'
import { state } from './state'

export default {

	directives: { autofocus },

	data: () => ({
		state,
		name: '',
		remember: state.remember
	}),

	methods: {
		select() {
			this.state.goSelectWith(this.name, this.remember);
		},
		cancel() {
			this.state.goView();
		}
	}
}
</script>

<template>
	<div class="AssigneeSelector">
		<form @submit.prevent="select()">
		
			<input
				class="input"
				v-autofocus
				v-model="name"
				@keydown.escape="cancel()"
				placeholder="Mon nom"
				spellcheck="false" />
			
			<label class="remember">
				Se souvenir de mon nom <input type="checkbox" v-model="remember" />
			</label>
		
		</form>
	</div>
</template>

<style scoped>

.AssigneeSelector {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	color: #EEE;
}
@media screen and (min-width: 541px) {
	.AssigneeSelector {
		flex-direction: column-reverse;
	}
}

.input {
	width: 100%;
	margin: .5em 0;
}
.input::placeholder {
	font-style: italic;
	opacity: .4;
}

.remember {
	display: block;
	text-align: right;
	font-size: 70%;
	cursor: pointer;
}
.remember > input {
	vertical-align: middle;
	margin: 0;
	margin-left: .2em;
}

</style>
