<script>
import { state } from './state'

import ViewModal from '@/components/ViewModal.vue'
import ViewLayout from '@/components/ViewLayout.vue'
import WeeklyCalendar from '@/components/WeeklyCalendar.vue'
import PermViewDay from './PermViewDay.vue'
import AssigneeSelector from './AssigneeSelector.vue'
import AssigneeDisplay from './AssigneeDisplay.vue'
import ActionButtons from './ActionButtons.vue'

export default {

	components: { ViewModal, ViewLayout, WeeklyCalendar, PermViewDay, AssigneeSelector, AssigneeDisplay, ActionButtons },

	data: () => ({
		state,
		loading: false,
		saved: false
	}),

	async mounted() {
		this.loading = true;
		await state.load();
		this.loading = false;
	}
}

</script>

<template>
	<ViewLayout class="PermView">

		<ActionButtons
			slot="tools"
			v-if="state.selecting"
			:selectionCount="state.toggledOccurrences.length"
			@submit="state.goSave(), saved = true"
			@cancel="state.goView()"
		/>
		
		<AssigneeDisplay
			slot="user"
			class="user"
			v-if="state.selecting"
			:assignee="state.currentAssignee"
			@click.native="state.goEdit()"
		/>
		
		<button
			slot="action"
			class="register button primary"
			v-if="state.viewing && !saved"
			@click="state.goSelectWithCurrent()">
			Inscription / désinscription <FaIcon icon="check-double"/>
		</button>

		<WeeklyCalendar :weeks="state.weeks" v-slot="{ day }">
			<PermViewDay :day="day" @occurrenceSelected="state.selectOccurrence($event.occurrence, $event.type)"/>
		</WeeklyCalendar>

		<ViewModal
			v-if="state.editing"
			:nearToKeyboard="true"
			@click-out="state.goView(), saved = false">
			<AssigneeSelector/>
		</ViewModal>
		
		<ViewModal v-if="state.viewing && saved" @click="saved = false" @click-out="saved = false">
			<div class="hooray"> <FaIcon icon="laugh"/> Inscription validée !</div>
			<div>Si je ne peux pas venir, je n'oublie pas de me désinscrire et de prévenir mes camarades bénévoles.</div>
		</ViewModal>

	</ViewLayout>
</template>

<style scoped>

.user {
	padding-left: 1.5rem;
	border-left: dashed 1px #333;
}

.register {
	box-shadow: 3px 3px 15px #000;
}
.register > svg {
	font-size: 120%;
	margin-left: .5em;
}

.hooray {
	padding: 1em 0;
	font-size: 150%;
	text-decoration: underline var(--app-accent-color) 3px;
}
.hooray > svg {
	font-size: 160%;
	vertical-align: middle;
	color: var(--app-accent-color);
}

</style>
