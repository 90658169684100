import { DateTime } from 'luxon'


/******** API MODEL ********/

export type WeekDayValue = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type AssigneeType = 'REF' | 'BEN';

export interface WeeklySlot {
	weekday: WeekDayValue
	hour: number
	minute: number
}

export interface WeeklyOccurrence {
	assignees?: {
		[name: string]: AssigneeType
	}
}

export interface GetData {
	slots: WeeklySlot[]
	occurrences: {
		[instant: string]: WeeklyOccurrence
	}
	events: {
		[instant: string]: string
	}
}

export interface SetData {
	assignees?: {
		[name: string]: {
			[instant: string]: AssigneeType | false
		}
	}
	events?: {
		[instant: string]: string
	}
}


/******** API SERVICE ********/

const API_CFG = 'api/conf.json';
const API_GET = 'api/data.json';
const API_SET = 'api/data.php';

export const Api = {

	async get(): Promise<GetData> {

		const [ config, data ] = await Promise.all([
			httpGetJson(API_CFG, false),
			httpGetJson(API_GET, true, {})
		]);

		return {
			slots: config.slots,
			occurrences: data.occurrences,
			events: data.events
		};
	},

	set(data: SetData): Promise<Response> {
		return httpPostJson(API_SET, data);
	},

	dt2str(instant: DateTime) {
		return instant.toISO({ suppressMilliseconds: true, includeOffset: false });
	},

	str2dt(str: string): DateTime {
		return DateTime.fromISO(str);
	},
	
	d2str(instant: DateTime) {
		return instant.toISODate();
	}

};


/******** UTILS ********/

function httpGetJson(url: string, noCache: boolean, defaultValue?: any) {
	
	let init: any = undefined;
	
	if (noCache) {
		const headers = new Headers();
		headers.append('Cache-Control', 'no-cache');
		init = { headers };
	}
	
	return fetch(url, init)
		.then(r => {
			if (Math.floor(r.status / 100) == 2) return r.json();
			if (defaultValue !== undefined && r.status == 404) return defaultValue;
			return Promise.reject(r);
		});
}

function httpPostJson(url: string, data: any) {
	return fetch(url, { method: 'POST', body: JSON.stringify(data) })
		.then(r => {
			if (Math.floor(r.status / 100) == 2) return r;
			return Promise.reject(r);
		});
}
